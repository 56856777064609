import './App.css';
import React, { lazy, Suspense } from "react";
import NavBar from './Components/Navbar/Navbar';
import {  Routes, Route, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorfallbackRender from './Components/ErrorBoundary/ErrorBoundary';
import CurentOpening from './Components/Career/Current-Openings';
// import JobDescription from './Components/Career/JobDescription';
// import ApplyPage from './Components/Career/ApplyPage';


const LazyHome = lazy(() => import("./Components/HomePage/HomePage"));
const LazyFooter = lazy(() => import("./Components/Footer/Footer"));
const LazyDigitales = lazy(() => import("./Components/Blog/Digitales"));
const LazyInsight = lazy(()=> import ("./Components/Blog/Insight"))
const LazyContact = lazy(()=> import ("./Components/Contact/Contact"))
const LazyAbout = lazy(() => import("./Components/AboutUs/aboutus"));
const LPortfolio = lazy(()=> import("./Components/Portfolio/Portfolio"))
const LDesign =  lazy(()=> import("./Components/HomePage/Design"))
const LDevelopment =  lazy(()=> import("./Components/HomePage/Development"))
const LMarketing =  lazy(()=> import("./Components/HomePage/Digital-Marketing"))
const LConsulting =  lazy(()=> import("./Components/HomePage/Digital-Consulting"))
const LArticle =  lazy(()=> import("./Components/Blog/Article"))
const LService =  lazy(()=> import("./Components/Services/Service"))
const LPortfolioArticle = lazy(() =>
  import("./Components/Portfolio/PortfolioArticle")
);
const LazyCulture = lazy(() => import("./Components/Career/Culture"))
const Openings = lazy(() => import("./Components/Career/Current-Openings"))
const JobDescription = lazy(() => import("./Components/Career/JobDescription"));
const ApplyPage = lazy(() => import("./Components/Career/ApplyPage"));


function App() {
  const navigate = useNavigate()
  return (
    <ErrorBoundary
      fallbackRender={ErrorfallbackRender}
      onReset={(details) => {
        navigate("/");
      }}
    >
      <div className="">
        <Suspense
          fallback={<div className="loading-container">Loading...</div>}
        >
          <NavBar />
          <Routes>
            <Route exact path="/" element={<LazyHome />} />
            <Route exact path="/services" element={<LService />} />
            <Route exact path="/design" element={<LDesign />} />
            <Route exact path="/development" element={<LDevelopment />} />
            <Route exact path="/digital-marketing" element={<LMarketing />} />
            <Route exact path="/digital-consulting" element={<LConsulting />} />
            <Route exact path="/article/:articleTitle" element={<LArticle />} />
            <Route exact path="/digitales" element={<LazyDigitales />} />
            <Route exact path="/insight" element={<LazyInsight />} />
            <Route exact path="/contact" element={<LazyContact />} />
            <Route exact path="/about" element={<LazyAbout />} />
            <Route exact path="/portfolio" element={<LPortfolio />} />
            <Route exact path="/portfolio/portfolio-article/:id" element={<LPortfolioArticle />}/>
            <Route exact path="/culture" element={<LazyCulture />} />
            <Route exact path="/current-openings" element ={<Openings/>} />
            <Route exact path="/current-openings/know-more/:id" element={<JobDescription />}/>
            <Route exact path="/apply-now" element ={<ApplyPage/>}/>
          </Routes>

          <LazyFooter />
        </Suspense>
      </div>
    </ErrorBoundary>
  );
}

export default App;
