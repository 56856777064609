import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import logo from "./scketch-logo.webp";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ImMenu } from "react-icons/im";
import { AiFillCloseSquare } from "react-icons/ai";

const NavBar = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [showmedia, setMedia] = useState(false);
  const fetchNavData = () => {
    axios
      .get("https://strapi-dev.scketch.com/api/menus?nested&populate=*")
      .then((res) => {
        setData(res?.data?.data?.[0]?.attributes?.items?.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchNavData();
  }, []);
  return (
    <div
      className="n-wrapper"
      style={{ width: "100% !important", height: "120px !important" }}
    >
      <div className="n-left" onClick={() => navigate("/")}>
        <img loading="lazy" src={logo} alt="logo" height="100%" width="100%" />
      </div>
      <div className="n-right">
        <ul>
          {data?.length !== 0 &&
            data?.map((data, index) => {
              return (
                <div className="dropdown" key={data.id}>
                  <Link className="link" to={data?.attributes?.url}>
                    {data?.attributes?.title}
                  </Link>
                  <div className="dropdown-content">
                    {data?.attributes?.children?.data?.map((data) => {
                      return (
                        <Link
                          key={data?.id}
                          className="link"
                          to={data?.attributes?.url}
                        >
                          {data?.attributes?.title}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </ul>
      </div>
      <div className="nav-hamburger">
        <ImMenu
          size={26}
          color="#F17983"
          className="nav-ham-menu-button"
          onClick={() => setMedia(!showmedia)}
        />
        <div className={showmedia ? "nav-mobile" : "nav-hide"}>
          <div className="close-nav-container">
            <AiFillCloseSquare
              color="#F17983"
              size={30}
              onClick={() => setMedia(!showmedia)}
              className="close-nav-btn"
            />
          </div>
          <ul>
            {data?.length !== 0 &&
              data?.map((data, index) => {
                return (
                  <div className="show-menu" key={data.id}>
                    <Link className="link " to={data?.attributes?.url}>
                      {data?.attributes?.title}
                    </Link>
                    {data?.attributes?.children?.data?.map((data) => {
                      return (
                        <Link
                          key={data?.id}
                          className=" show-sub-menu"
                          to={data?.attributes?.url}
                          onClick={() => setMedia(!showmedia)}
                        >
                          {data?.attributes?.title}
                        </Link>
                      );
                    })}
                  </div>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default NavBar;
