import { RiErrorWarningFill } from "react-icons/ri";
import "./ErrorBoundary.scss";

function ErrorfallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div className="error-fallback-container" role="alert">
      <RiErrorWarningFill size={58} color="grey" />
      <p className="error-title">Oops! Something went wrong</p>
      {/* <p className="error-msg">{error.message}</p> */}
      <button onClick={() => resetErrorBoundary()}>Try again</button>
    </div>
  );
}

export default ErrorfallbackRender;
