import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./openings.scss";
const CurentOpening = () => {
  const [data, setData] = useState([]);
  const [bannerImage, setBannerImage] = useState({});
  const [category, setcategory] = useState("All");
  const [categoryData, setcategoryData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllJobs = () => {
    axios
      .get("https://strapi-dev.scketch.com/api/job-categories?populate=%2A")
      .then((res) => {
        setData(res?.data?.data);
        setcategoryData(
          res?.data?.data?.[0]?.attributes?.current_openings?.data
        );
        console.log("log from fetchAllJobs: ", res?.data?.data);
      })
      .catch((err) => {
        console.log("Error from fetchAllJobs: ", err);
      });
  };

  const getDisplayData = () => {
    const req_data = data?.filter(
      (e) => e?.attributes?.categoryName === category
    )?.[0];
    setcategoryData(req_data?.attributes?.current_openings?.data);
  };

  useEffect(() => {
    fetchAllJobs();
  }, []);
  useEffect(() => {
    axios
      .get("https://strapi-dev.scketch.com/api/current-openings?populate=%2A")
      .then((res) => {
        setBannerImage(res?.data?.data[3]);
        setLoading(false);
      })
      .catch((err) => {
        alert("Error: ", err);
      });
  }, [category]);

  useEffect(() => {
    getDisplayData();
  }, [category]);
  return (
    <div className="text-align main-div mt-5">
      {!loading
        ? bannerImage?.length !== 0 && (
            <div className="p-20 banner-data">
              <div className="text-align banner-padding">
                <span className="first">Yes, we are looking for our</span>
                <br />
                <span className="first">
                  <b>Darth Vader</b>
                </span>
                <br />
                <span className="first">Senior Designer – UX/UI</span>
                <br />
              </div>
              <div>
                <img
                  src={bannerImage?.attributes?.imageUrl}
                  width="100%"
                  height="100%"
                  alt="opening-banner-img"
                />
              </div>
            </div>
          )
        : "Loading..."}
      {data?.length !== 0 &&
        data?.map((data) => {
          return (
            <div
              className={`category-btn ${
                data?.attributes?.categoryName === category &&
                "selected-job-category"
              }`}
              onClick={() => setcategory(data.attributes.categoryName)}
            >
              {data.attributes.categoryName}
            </div>
          );
        })}
      {categoryData?.length !== 0 ? (
        categoryData?.map((data) => {
          return (
            <div className="category-data">
              <div className="left-side">
                <img
                  src={data.attributes.imageUrl}
                  width="100%"
                  height="100%"
                  alt="category-left-img"
                />
              </div>
              <div className="right-side">
                <h3>{data.attributes.title}</h3>
                <p>{data.attributes.description}</p>
                <Link to={`know-more/${data?.id}`} className="content-link">
                  Know More
                </Link>
              </div>
            </div>
          );
        })
      ) : (
        <div>No openings currently in this department</div>
      )}
      <h4 style={{ marginTop: "30px" }}>
        <Link to="/culture" style={{ color: "black" }}>
          See the Scketch Culture
        </Link>
      </h4>
    </div>
  );
};
export default CurentOpening;
